import { Col, Container, Row } from "react-bootstrap";

export const Footer = () => {
  return <>
  <div className="footer-section">
    <Container>
      <Row className="justify-content-center">
        <Col sm={12} md={6} lg={4}>
          <div className="footer-content text-center">
            <img src={require('../assets/images/Icon.svg').default} className="mb-3" alt="logo" />
            <h4>Used by the world’s Insurance Sector</h4>
            <h5>AI Office, 2024.</h5>
          </div>
        </Col>
        {/* <Col sm={6} md={3} lg={2}>
          <div className="footer-link">
            <h6>Platform</h6>
            <ul>
              <li>
                <a href="#">Plans & Pricing</a>
              </li>
              <li>
                <a href="#">Personal AI Manager</a>
              </li>
              <li>
                <a href="#">AI Business Writer</a>
              </li>
            </ul>
          </div>
        </Col>
        <Col sm={6} md={3} lg={2}>
          <div className="footer-link">
            <h6>Company</h6>
            <ul>
              <li>
                <a href="#">Blog</a>
              </li>
              <li>
                <a href="#">Careers</a>
              </li>
              <li>
                <a href="#">News</a>
              </li>
            </ul>
          </div>
        </Col>
        <Col sm={6} md={6} lg={2}>
          <div className="footer-link">
            <h6>Resources</h6>
            <ul>
              <li>
                <a href="#">Documentation</a>
              </li>
              <li>
                <a href="#">Papers</a>
              </li>
              <li>
                <a href="#">Press Conferences</a>
              </li>
            </ul>
          </div>
        </Col>
        <Col sm={6} md={6} lg={2}>
          <div className="footer-link">
            <h6>Get the app</h6>
            <ul>
              <li>
                <a href="#" className="get-app-btn">
                  <img src={require('../assets/images/window.svg').default} className="" alt="logo" /> Windows
                </a>
              </li>
              <li>
              <a href="#" className="get-app-btn">
                  <img src={require('../assets/images/mac.svg').default} className="" alt="logo" /> macOS
                </a>
              </li>
            </ul>
          </div>
        </Col> */}
      </Row>
    </Container>
  </div>
  <div className="footer-bottom">
    <Container>
      <Row>
        <Col md={6}>
          <div className="btm-footer-content">
            <p>© 2024 AI Office. All rights reserved.</p>
          </div>
        </Col>
        <Col md={6}>
          <div className="btm-footer-content">
            <ul>
              <li>
                <a href="/terms-condition">Terms of Service</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              {/* <li>
                <a href="#">Cookies</a>
              </li> */}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
  </>;
};

