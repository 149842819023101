import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export const TermsConditions = () => {


  const navigate = useNavigate();

  return (



    <>
      {/* terms and condition section start */}
      <section className="terms-section common-padding">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <h2 className="main-heading">Terms & Conditions</h2> 
              <p className="my-2"><b>Last Updated</b></p> 
              <p>Welcome to AIOffice! These terms and conditions ("Terms") govern your use of our AI call management and automated call answering service. By accessing or using AIOffice, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our services.</p>
             
              <h4 className="mt-3">1. Use of Services</h4>
              <p>To access certain features, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

              <h4 className="mt-3">2. Service Description</h4>
              <p>AIOffice is designed to assist in call management and automate call answers using artificial intelligence. While we strive for accuracy and reliability, we do not guarantee the completeness, accuracy, or reliability of our services.</p>

              <h4 className="mt-3 mb-2">3. User Responsibilities</h4>
              <h5>3.1 Acceptable Use</h5>
              <p>You agree not to use AI Office for any unlawful or prohibited purpose. You also agree to comply with all applicable laws and regulations.</p>

              <h5 className="mt-3">3.2 Data Accuracy</h5>
              <p>You are responsible for the accuracy and legality of the data you provide to AI Office. We are not liable for any consequences resulting from inaccurate or illegal data.</p>

              <h4 className="mt-3">4. Privacy</h4>
              <p>Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using AI Office, you consent to the terms outlined in our Privacy Policy.
              </p>

              <h4 className="mt-3">5. Intellectual Property</h4>
              <p> AI Office and its content are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without our explicit permission.
              </p>

              <h4 className="mt-3">6. Limitation of Liability</h4>
              <p>To the maximum extent permitted by law, AI Office and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages
              </p>

              <h4 className="mt-3">7. Termination</h4>
              <p>We reserve the right to terminate or suspend your access to AI Office at our discretion, without notice and for any reason, including violation of these Terms.
              </p>

              <h4 className="mt-3">8. Changes to Terms</h4>
              <p>We may update these Terms periodically. The date of the latest revision will be displayed at the top. Continued use of AI Office after such changes constitutes your acceptance of the new Terms
              </p>


              <h4 className="mt-3">9. Contact Us</h4>
              <p>If you have any questions, concerns, or feedback about these Terms, please contact us.
By using AI Office, you agree to the terms outlined in these Terms and Conditions
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* terms and condition section ends */}
    </>
  );
};
