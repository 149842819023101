import { ThreeDots } from 'react-loader-spinner';

export const Loader = () => {
    return (
        <>
            <section className='loader-area'>
                <div className='loader'>
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#7D55D5"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            </section>
        </>
    );
};
