import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export const PrivacyPolicy = () => {


  const navigate = useNavigate();

  return (



    <>
      {/* terms and condition section start */}
      <section className="terms-section common-padding">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <h2 className="main-heading">Privacy Policy for AIOffice</h2>
              <p className="my-2"><b>Last Updated</b></p>
              <p>Thank you for choosing Aioffice! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services</p>

              <h4 className="mt-3">1. Information We Collect</h4>
              <h5 className="mt-3 mb-2">1.1 Personal Information</h5>
              <p>When you use AI Office, we may collect personal information that you provide voluntarily, such as:</p>
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Company information</li>
              </ul>

              <h5 className="mt-3 mb-2">1.2 Call Data</h5>
              <p>To improve our AI's performance and provide better service, we may collect and process call data, including:</p>
              <ul>
                <li>Call duration</li>
                <li>Call recordings (if enabled)</li>
                <li>Call transcripts</li>
              </ul>

              <h4 className="mt-3">2. How We Use Your Information</h4>
              <p>We use the information collected for the following purposes:</p>
              <ul>
                <li>To Provide Services: Delivering and maintaining our AI call management services.
                </li>
                <li>To Improve Services: Analyzing data to enhance our product's functionality, features, and user experience</li>
                <li>To Communicate: Sending relevant updates, notifications, and support messages.
                </li>
                <li>To Comply with Legal Obligations: Meeting legal requirements and responding to lawful requests.</li>
              </ul>

              <h4 className="mt-3">3. Data Security</h4>
              <p>We prioritize the security of your information and employ industry-standard measures to protect against unauthorized access, disclosure, alteration, and destruction.
              </p>

              <h4 className="mt-3">4. Sharing Your Information
              </h4>
              <p>We do not sell, trade, or rent your personal information to third parties. However, we may share information under the following circumstances</p>
              <ul>
                <li>With your consent.
                </li>
                <li>If required by law or to protect our legal rights.
                </li>
                <li>In the event of a merger, acquisition, or sale of all or part of our assets.

                </li>
              </ul>

              <h4 className="mt-3">5. Your Choices and Rights

              </h4>
              <p>You have the right to:
              </p>
              <ul>
                <li>Access and update your personal information.

                </li>
                <li>Opt-out of marketing communications.</li>

                <li>Request the deletion of your account and data.

                </li>
              </ul>

              <h4 className="mt-3">6. Cookies and Tracking Technologies


              </h4>
              <p>We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.

              </p>

              <h4 className="mt-3">7. Changes to this Privacy Policy


              </h4>
              <p>We may update this Privacy Policy periodically. The date of the latest revision will be displayed at the top.

              </p>

              <h4 className="mt-3">8. Contact Us



              </h4>
              <p>If you have any questions, concerns, or requests regarding your privacy, please contact us. By using aioffice, you agree to the terms outlined in this Privacy Policy.



              </p>


            </Col>
          </Row>
        </Container>
      </section>
      {/* terms and condition section ends */}
    </>
  );
};
