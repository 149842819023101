import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export const Feature = () => {


  const navigate = useNavigate();

  return (
    <>
      {/* feature section start */}
      <section className="feature-contant-section">
        <Container>
          <div className="features-heading">
            <h4 className="upper-heading"><i className="fa-solid fa-newspaper"></i> Feature</h4>
            <h1 className="main-heading shimmer">AI auto call agent that boost self-service call experience
            </h1>
          </div>
          <Row className="margin-top align-items-center">
            <Col md={6}>
              <div className="feature-img-section">
                <img src={require('../assets/images/ai-feature.png')} alt="logo" />
              </div>
            </Col>
            <Col md={6}>
              <div className="feature-content-section">
                <h2 className="main-heading">
                Get the latest AI technology to answer your phone calls.
                </h2>
                <h5>Here you can see many features, and some of them are</h5>
                <p><span>&#8658;</span> AI-Powered Insurance Agent (Anna)</p>
                <p><span>&#8658;</span> Enhanced Communication</p>
                <p><span>&#8658;</span> Real-Time Query Handling</p>
                <p><span>&#8658;</span> Call Summary</p>
                <p><span>&#8658;</span> Customizable Interaction Flow</p>
                <p><span>&#8658;</span> 24/7 Availability</p>
                <p><span>&#8658;</span> Scalable for Different Insurance Products</p>
                <p><span>&#8658;</span> Cost Efficiency</p>
                <p><span>&#8658;</span> User-Friendly Interface</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center margin-top">
            <Col lg={4} className="mb-4" data-aos="fade-right">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> AI-Powered Insurance Agent (Anna):</h4>
                <ul>
                  <li>Anna is a conversational AI designed specifically for the insurance sector, providing intelligent and context-aware interactions. She engages customers by asking for relevant personal details such as age, lifestyle, and insurance needs, and uses this information to suggest tailored insurance plans. This ensures that customers receive recommendations that best fit their individual circumstances, enhancing customer satisfaction and trust.</li>
                </ul>
                <div className="numb">
                  01
                </div>
              </div>

            </Col>
            <Col lg={4} className="mb-4">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> Enhanced Communication:</h4>
                <ul>
                  <li>Unlike traditional IVR systems that can be robotic and difficult to navigate, Anna offers a more human-like interaction. With advanced natural language processing (NLP) capabilities, she can understand complex queries, handle varied accents, and respond with clarity and precision. This results in a smoother, more effective communication experience for the user.</li>
                </ul>
                <div className="numb">
                  02
                </div>
              </div>
            </Col>
            <Col lg={4} className="mb-4" data-aos="fade-left">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> Real-Time Query Handling:</h4>
                <ul>
                  <li>Customers are not confined to a rigid script. They can interrupt Anna at any point to ask questions, seek clarification, or change the topic. This flexibility empowers customers to drive the conversation, ensuring their needs are met promptly and accurately without frustration.</li>
                </ul>
                <div className="numb">
                  03
                </div>
              </div>
            </Col>
            <Col lg={4} className="mb-4" data-aos="fade-right">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> Call Summary:</h4>
                <ul>
                  <li>After each interaction, aioffice.pro automatically generates a comprehensive call summary. This includes key points discussed, decisions made, and any follow-up actions required. These summaries can be stored and accessed later, providing both the customer and the insurance company with a clear record of the conversation. This feature is particularly valuable for ensuring transparency and for future reference. </li>
                </ul>
                <div className="numb">
                  04
                </div>
              </div>
            </Col>
            <Col lg={4} className="mb-4">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" />  Customizable Interaction Flow:</h4>
                <ul>
                  <li>The AI agent’s interaction flow is highly adaptable, allowing insurance companies to customize the conversation structure based on their specific business requirements. Whether it’s focusing on certain insurance products, asking additional questions, or simplifying the interaction, the AI’s flexibility ensures that it aligns with the company’s objectives and the customer’s needs.</li>
                </ul>
                <div className="numb">
                  05
                </div>
              </div>

            </Col>
            <Col lg={4} className="mb-4" data-aos="fade-left">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> 24/7 Availability:</h4>
                <ul>
                  <li>Anna is always on, providing uninterrupted service to customers regardless of the time or day. This ensures that customers can receive assistance whenever they need it, whether it’s during business hours or late at night, improving customer service accessibility and satisfaction.</li>
                </ul>
                <div className="numb">
                  06
                </div>
              </div>
            </Col>
            <Col lg={4} className="mb-4" data-aos="fade-right">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> Scalable for Different Insurance Products:</h4>
                <ul>
                  <li>aioffice.pro is versatile and can be used across various insurance products, including life, health, auto, and home insurance. This scalability makes it a comprehensive tool for insurance companies looking to enhance customer engagement across multiple product lines.</li>
                </ul>
                <div className="numb">
                  07
                </div>
              </div>
            </Col>
            <Col lg={4} className="mb-4">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> Cost Efficiency:</h4>
                <ul>
                  <li>By automating routine inquiries and interactions, aioffice.pro reduces the need for a large customer service team, leading to significant cost savings for insurance companies. This efficiency also allows human agents to focus on more complex customer needs, improving overall service quality. </li>
                </ul>
                <div className="numb">
                  08
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4" data-aos="fade-left">
              <div className="feature-inner-box">
                <h4><img src={require('../assets/images/star.svg').default} alt="logo" /> User-Friendly Interface:</h4>
                <ul>
                  <li><p>Ai Office boasts an intuitive and user-friendly interface, making it accessible for both staff and customers.</p>
                    <p>Through clear and straightforward navigation, users can easily leverage the features related to understanding caller queries, availability scheduling, and reservation management.</p>
                  </li>

                </ul>
                <div className="numb">
                  09
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* feature section ends */}
    </>
  );
};
