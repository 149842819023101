import { Accordion, Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import { ContactUs } from "./ContactUs";

export const Home = () => {


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 1);
  }, [])

  return (
    <>
      {/* hero main section starts */}
      <section className="hero-section">
        <Container fluid>
          <Swiper
            slidesPerView={1}
            navigation={true}
            effect={'fade'}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper hero-container"
            breakpoints={{

            }}
          >
            <SwiperSlide>
              <Row className="side-padding align-items-center">
                <Col md={6}>
                  <div className="hero-content-section" >
                    {/* <h3><img src={require('../assets/images/star.svg').default} alt="logo" />
                  Welcome to AI OFFICE </h3> */}
                    <h1 className="shimmer"><span>Conversational AI <img className="call-img" src={require('../assets/images/call (1).svg').default} alt="logo" /></span><br></br> for Insurance Industry</h1>
                    <h5>Don’t struggle with insurance complexities—our conversational AI provides straightforward explanations, so you can make informed choices effortlessly.</h5>
                    <Button className="get-started-btn" onClick={() => navigate("/contact-us")}>
                      Get Started for Free <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                    </Button>
                    <Button className="get-started-btn get-started-for-free" onClick={() => navigate("/contact-us")}>
                      Get Demo <img src={require('../assets/images/arrow-icon.svg').default} alt="logo" />
                    </Button>
                  </div>
                </Col>
                <Col md={6}>
                  <img src={require('../assets/images/banner-right.png')} alt="logo" className="hero-img" />
                </Col>
              </Row>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>
      <div className="help-ouer-section" data-aos="fade-up"
      >
        <Container fluid>
          <Row className="justify-content-center side-padding">
            <Col md={6}>
              <div className="help-box">
                <h2>Your AI Insurance Advisor, Ready to Assist</h2>
                <p>Empower your insurance decisions with smart, AI-driven conversations. Understand your policies fully and make choices that are right for you.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="help-box">
                <h2>Human-level conversations</h2>
                <p>Customers can communicate naturally, just as they would with a human agent—responding to questions, shifting topics, and expressing themselves freely.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* hero main section ends */}

      {/* AI Talent section stars */}
      <section className="ai-talent-section common-padding">
        <Container>
          <Row>
            <Col md={6}>
              <div className="ai-talent-content">
                <img src={require('../assets/images/Blue_check_mark.svg').default} alt="logo" className="hero-img" />
                <div className="under-talent-content">
                  <h3>Human-like Conversation Skills
                  </h3>
                  <p>Fully understands customer intents, context, and sentiment to provide tailored service around their exact needs.</p>
                </div>
              </div>
              <div className="ai-talent-content">
                <img src={require('../assets/images/Blue_check_mark.svg').default} alt="logo" className="hero-img" />
                <div className="under-talent-content">
                  <h3>Human-like Conversation Skills
                  </h3>
                  <p>Fully understands customer intents, context, and sentiment to provide tailored service around their exact needs.</p>
                </div>
              </div>
              <div className="ai-talent-content">
                <img src={require('../assets/images/Blue_check_mark.svg').default} alt="logo" className="hero-img" />
                <div className="under-talent-content">
                  <h3>Enterprise Knowledge
                  </h3>
                  <p>Pretrained in your support knowledge, industry know-how, and enterprise systems for superior query resolution.</p>
                </div>
              </div>
              <div className="ai-talent-content">
                <img src={require('../assets/images/Blue_check_mark.svg').default} alt="logo" className="hero-img" />
                <div className="under-talent-content">
                  <h3>Multilingual Proficiency
                  </h3>
                  <p>Fluent in over 100 languages to help customers in their native language.</p>
                </div>
              </div>
              <div className="ai-talent-content">
                <img src={require('../assets/images/Blue_check_mark.svg').default} alt="logo" className="hero-img" />
                <div className="under-talent-content">
                  <h3>24/7 Omnichannel Availability
                  </h3>
                  <p>On-call round the clock to offer support when it matters the most - on the channel of customers’ choice (phone and messaging).</p>
                </div>
              </div>
              <div className="ai-talent-content">
                <img src={require('../assets/images/Blue_check_mark.svg').default} alt="logo" className="hero-img" />
                <div className="under-talent-content">
                  <h3>Adaptive Learning
                  </h3>
                  <p>Improves with every user interaction and constantly acquires new knowledge and service skills.</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="under-right-ai-talent">
                <h2 className="main-heading mb-2">Hire the Best AI Talent for Your Business</h2>
                <p>Whether you are looking for a cheerful retail service agent or a 10-year veteran insurance representative, our AI workforce comes with the best-suited persona, skills, and qualities for the job.</p>
                <img src={require('../assets/images/AI agent travel-1.webp')} alt="logo" className="hero-img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* AI Talent section ends */}

      {/* Unmatched section starts */}
      <section className="unmatched-talent-section common-padding text-center">
        <Container>
          <h2 className="main-heading text-center mb-2">Unmatched Time-to-Market
          </h2>
          <p>The labor shortage is real. Cognigy’s AI Agents are fully trained and delivered according to your use cases, specifications, and business outcomes. That way, you can onboard them at lightning speed and go live within weeks to ensure timely and undisrupted customer service.</p>
        </Container>
      </section>
      {/* Unmatched section ends */}

      {/* missed section starts */}
      <section className="missed-section" data-aos="fade-up"
      >
        <Container>
          <h4 className="upper-heading"><i className="fa-solid fa-newspaper"></i> Services</h4>
          <h2 className="main-heading text-center">
            Offer customers a seamless<br></br> self-service experience across voice
          </h2>
          {/* <p className="text-center">Keep your outbound teams a step ahead with tools like sentiment analysis, live Ai coaching, and real-time assists.</p> */}
          <Row className="mt-4 justify-content-center">
            <Col lg={4} md={6}>
              <div className="missed-card-box gray">

                <h3 className="mb-2">Understand Callers.</h3>
                <p> AI Office comprehends callers of all demographics, answering queries, and providing real-time responses without cumbersome phone menus.</p>
                <a href="#" className="read-more">Read More <i className="fa-solid fa-arrow-right"></i></a>
                <img src={require('../assets/images/missed-1.svg').default} className="mb-2" alt="logo" />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="missed-card-box red">
                <h3 className="mb-2">Availability Scheduling.</h3>
                <p>Availability scheduling plays a pivotal role in systems dedicated to the effective management and optimization of resource and service utilization.</p>
                <a href="#" className="read-more">Read More <i className="fa-solid fa-arrow-right"></i></a>
                <img src={require('../assets/images/missed-3.svg').default} className="mb-2" alt="logo" />

              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="missed-card-box orange">
                <h3 className="mb-2">Manage Calls</h3>
                <p>Enable callers to swiftly book or modify reservations, keeping your staff informed without direct involvement.</p>
                <a href="#" className="read-more">Read More <i className="fa-solid fa-arrow-right"></i></a>
                <img src={require('../assets/images/missed-2.svg').default} className="mb-2" alt="logo" />

              </div>
            </Col>

          </Row>
        </Container>
      </section>
      {/* missed section ends */}



      {/* how works section starts */}
      <section className="how-works-section" data-aos="fade-up"
      >
        <Container>
          <h4 className="upper-heading"><i className="fa-brands fa-creative-commons-nd"></i> Working process</h4>
          <h2 className="main-heading text-center">
            How it works, in three easy steps.
          </h2>
          <Row className="align-items-center mt-4 ">
            <Col lg={12}>
              <Row>
                <Col lg={4}>
                  <div className="how-works-box">
                    <h2>Book demo </h2>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="how-works-box">
                    <h2>Get call within 5 seconds
                    </h2>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="how-works-box">
                    <h2>Switch your Business on AI Agent</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mt-4 background-differ">
            <Col lg={7}>
              <div className="how-work-img">
                <img src={require('../assets/images/demo-image.png')} alt="logo" />
              </div>
            </Col>
            <Col lg={5}>
              <div className="how-work-content">
                <h3>Book a Demo
                </h3>
                {/* <h3>Sign up with US. </h3> */}
                {/* <h4>Lorem ipsum dolor consectetur.</h4> */}
                <div className="works-box">
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      1
                    </span>
                    <span>
                      Schedule a demo via the aioffice.pro platform.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      2
                    </span>
                    <span>
                      Fill out a quick form with your contact details.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      3
                    </span>
                    <span>
                      Choose a convenient time to experience how Anna, the AI agent, works.
                    </span>
                  </div>
                </div>
                <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                  Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-4 background-differ another-variant">
            <Col lg={5}>
              <div className="how-work-content">
                <h3>Get a Call Within 5 Seconds</h3>
                {/* <h4>Lorem ipsum dolor consectetur.</h4> */}
                <div className="works-box">
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      1
                    </span>
                    <span>
                      Receive a call from Anna almost instantly—within 5 seconds of booking the demo.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      2
                    </span>
                    <span>
                      Anna will interact with you, demonstrate her capabilities, and suggest tailored insurance plans.
                    </span>
                  </div>
                </div>
                <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                  Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                </Button>
              </div>
            </Col>
            <Col lg={7}>
              <div className="how-work-img">
                <img src={require('../assets/images/getacall.png')} alt="logo" />
              </div>
            </Col>

          </Row>
          <Row className="align-items-center mt-4 background-differ">
            <Col lg={7}>
              <div className="how-work-img">
                <img src={require('../assets/images/switchyourbusiness.png')} alt="logo" />
              </div>
            </Col>
            <Col lg={5}>
              <div className="how-work-content">
                <h3>Switch Your Business to AI Agent
                </h3>
                {/* <h3>Sign up with US. </h3> */}
                {/* <h4>Lorem ipsum dolor consectetur.</h4> */}
                <div className="works-box">
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      1
                    </span>
                    <span>
                      Integrate Anna into your business operations after the demo.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      2
                    </span>
                    <span>
                      Leverage Anna’s advanced AI capabilities for better transcription, understanding, and customer communication.
                    </span>
                  </div>
                  <div className="steps-box">
                    <span className="steps-iiner-round">
                      3
                    </span>
                    <span>
                      Enhance customer satisfaction and streamline business processes with a superior AI-driven system.
                    </span>
                  </div>
                </div>
                <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                  Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                </Button>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
      {/* how works section ends */}


      {/* recordings sections start */}
      <section className="recordings-area">
        <Container>
          <h2 className="main-heading text-center">Voice Experiences Reimagined</h2>
          <Row>
          <Col md={4}>
            <div className="recording-area-inner active">
              <div className="recordings-area-left">
                <img src={require('../assets/images/agent-kim-avatar.png')} />
                <div className="recordings-area-left-content">
                  <h6>Kim <span>Feamle</span></h6>
                  <ul>
                    <li>English (US)</li>
                    <li>Soft</li>
                    <li>Airlines</li>
                  </ul>
                </div>
              </div>
              <div className="recordings-area-right">
                 <img src={require("../assets/images/audio-off.svg").default} alt="img"/>
                 {/* <img src={require("../assets/images/audio-on.svg").default} alt="img"/> */}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="recording-area-inner">
              <div className="recordings-area-left">
                <img src={require('../assets/images/headphone-1.jpg')} />
                <div className="recordings-area-left-content">
                  <h6>Dan <span>Male</span></h6>
                  <ul>
                    <li>English (US)</li>
                    <li>Soft</li>
                    <li>Airlines</li>
                  </ul>
                </div>
              </div>
              <div className="recordings-area-right">
                 <img src={require("../assets/images/audio-off.svg").default} alt="img"/>
                 {/* <img src={require("../assets/images/audio-on.svg").default} alt="img"/> */}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="recording-area-inner">
              <div className="recordings-area-left">
                <img src={require('../assets/images/agent-kim-avatar2.png')} />
                <div className="recordings-area-left-content">
                  <h6>Ana <span>Feamle</span></h6>
                  <ul>
                    <li>English (US)</li>
                    <li>Soft</li>
                    <li>Airlines</li>
                  </ul>
                </div>
              </div>
              <div className="recordings-area-right">
                 <img src={require("../assets/images/audio-off.svg").default} alt="img"/>
                 {/* <img src={require("../assets/images/audio-on.svg").default} alt="img"/> */}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="recording-area-inner">
              <div className="recordings-area-left">
                <img src={require('../assets/images/avatar-3.png')} />
                <div className="recordings-area-left-content">
                <h6>Alex  <span>Male</span></h6>
                  <ul>
                    <li>English (US)</li>
                    <li>Soft</li>
                    <li>Airlines</li>
                  </ul>
                </div>
              </div>
              <div className="recordings-area-right">
                 <img src={require("../assets/images/audio-off.svg").default} alt="img"/>
                 {/* <img src={require("../assets/images/audio-on.svg").default} alt="img"/> */}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="recording-area-inner">
              <div className="recordings-area-left">
                <img src={require('../assets/images/agent-kim-avatar3.jpg')} />
                <div className="recordings-area-left-content">
                  <h6>Dave<span>Male</span></h6>
                  <ul>
                    <li>English (US)</li>
                    <li>Soft</li>
                    <li>Airlines</li>
                  </ul>
                </div>
              </div>
              <div className="recordings-area-right">
                 <img src={require("../assets/images/audio-off.svg").default} alt="img"/>
                 {/* <img src={require("../assets/images/audio-on.svg").default} alt="img"/> */}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="recording-area-inner">
              <div className="recordings-area-left">
                <img src={require('../assets/images/agent-kim-avatar4.jpg')} />
                <div className="recordings-area-left-content">
                  <h6>Alex<span>Male</span></h6>
                  <ul>
                    <li>English (US)</li>
                    <li>Soft</li>
                    <li>Airlines</li>
                  </ul>
                </div>
              </div>
              <div className="recordings-area-right">
                 <img src={require("../assets/images/audio-off.svg").default} alt="img"/>
                 {/* <img src={require("../assets/images/audio-on.svg").default} alt="img"/> */}
              </div>
            </div>
          </Col>
          </Row>
       
        </Container>
      </section>
      {/* recordings sections end */}
      {/* Industry section starts */}
      <section className="industry-section" id="industry-serve">
        <Container>
          <h4 className="upper-heading"><i className="fa-solid fa-building"></i> Industry</h4>
          <h2 className="main-heading text-center">
            Industry we serve
          </h2>
          <div className="industry-inner-section">
            <Tabs
              defaultActiveKey="insurance"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="insurance" title="Insurance">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/ins.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3>Appropriate features for proper use of Insurance</h3>
                      <p>AIoffice.pro can help in the insurance industry by providing 24/7 customer support and streamlining claims processing.
                      </p>
                      <p>They guide users through claims, offer personalized policy recommendations, and keep customers updated on policy details and renewals. In addition, AI aids in fraud detection by spotting unusual activities and assessing risks.
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="education" title="Education">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/education.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3>Supporting Students and Educators with AI</h3>
                      <p>The education sector is also leveraging aioffice.pro to enhance student support. They provide information on course materials, deadlines, and schedules, helping students manage their academic responsibilities more effectively.
                      </p>
                      <p>AI tutors offer personalized learning experiences, adjusting to the student's performance level and providing instant feedback.
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="banking" title="Banking">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/banking.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3>Enhancing Customer Support and Security</h3>
                      <p>In the banking sector, they play a crucial role in customer support by handling inquiries such as balance checks, transaction histories, and branch locations, significantly reducing wait times. Moreover, they are instrumental in fraud detection,
                      </p>
                      <p>where they promptly alert customers to suspicious activities and guide them through securing their accounts. Financial advice is another area where aioffice.pro excel, offering personalized recommendations for budget management and investments.
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="healthcare" title="Healthcare">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/healthcare.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3>Streamlining Patient Interaction with AI</h3>
                      <p>In healthcare, aioffice.pro improve patient interaction by scheduling appointments, sending reminders, and answering queries, which enhances the overall patient experience.
                      </p>
                      <p>aioffice.pro can answers patient queries in real time, providing quick responses to common questions. This instant access to information reduces patient anxiety and ensures they are better informed about their health
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="retail" title="Retail">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/retail.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3> Personalizing the Shopping Experience</h3>
                      <p>The retail industry benefits from aioffice.pro through personalized shopping experiences. These assistants recommend products based on previous purchases, browsing history, and customer preferences, leading to more tailored shopping experiences.
                      </p>
                      <p>AI-driven assistants efficiently handle returns, refunds, and product-related inquiries, freeing up human agents for more complex issues.
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="telecomunication" title="Telecomunication">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/telecommunication.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3>Optimizing Technical Support and Billing</h3>
                      <p>In telecommunications, aioffice.pro provide technical support by diagnosing and troubleshooting common issues, such as connectivity problems or billing inquiries, which reduces the need for human intervention.
                      </p>
                      <p>They also recommend the most suitable plans or services based on user data, optimizing customer satisfaction. Additionally, these assistants manage billing inquiries, offering assistance with payment reminders, processing payments, and addressing customer concerns.
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="hospitality" title="Hospitality">
                <Row>
                  <Col md={6} data-aos="fade-left">
                    <div className="left-industry-img">
                      <img src={require('../assets/images/hospitality.png')} alt="logo" />
                    </div>
                  </Col>
                  <Col md={6} data-aos="fade-right">
                    <div className="left-industry-content">
                      <h3>Elevating Guest Services and Personalization</h3>
                      <p>In the hospitality industry, aioffice.pro elevate guest services by handling room service orders, providing local recommendations, and assisting with check-in and check-out processes, thereby improving the guest experience. They are also crucial in managing bookings and reservations, offering updates on availability, and answering customer inquiries.
                      </p>
                      <p> Moreover, these assistants enhance the overall guest experience by offering personalized recommendations for activities, dining, and other experiences based on guest preferences.
                      </p>
                      <Button className="get-started-btn mt-4" onClick={() => navigate("/contact-us")}>
                        Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
      {/* Industry section starts */}

      {/* Industry section starts */}
      <section className="feature-section-home" >
        <Container>
          <h4 className="upper-heading"><i className="fa-solid fa-building"></i> Feature</h4>
          <h2 className="main-heading text-center">
            AI auto call agent that boost self-service call experience
          </h2>
          <div className="industry-inner-section-home">
            <img src={require('../assets/images/feature-main.png')} alt="logo" />
          </div>
        </Container>
      </section>
      {/* Industry section starts */}

      {/* testimonial section starts */}
      <section className="testimonial-section" data-aos="fade-up"
      >
        <Container>
          <h2 className="main-heading text-center">Trusted by 100+ businesses</h2>
          <p className="main-subheading text-center"> AiOffice gives you a superpower application that is remarkably better at transcribing, understanding, and speaking than traditional IVR systems.</p>
          <div className="outer-testimonial-box">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                575: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar.png')} alt="logo" />
                    <div>
                      <h5>Sarah</h5>
                      <p>CEO at Guidlor Corp</p>
                    </div>
                  </div>
                  <p className="mt-2">"Our virtual agent handles a workload equivalent to about 25 employees and plays a significant role in generating millions in sales."</p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar-2.png')} alt="logo" />
                    <div>
                      <h5>Chia</h5>
                      <p>Operations Manager at Distrix Services</p>
                    </div>
                  </div>
                  <p className="mt-2">"We're constantly exploring new ways to serve our customers, and the ability to scale our virtual agent to better meet their needs has been key to its success."</p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar-3 (2).png')} alt="logo" />
                    <div>
                      <h5>John</h5>
                      <p>Founder of Astra Solutions</p>
                    </div>
                  </div>
                  <p className="mt-2">"We're thrilled with the results so far. Providing 24/7 support to our customers has unlocked a whole new realm of opportunity." </p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-box">
                  <div className="inner-client-box">
                    <img src={require('../assets/images/avatar.png')} alt="logo" />
                    <div>
                      <h5>Sarah</h5>
                      <p>CEO at XYZ Corp</p>
                    </div>
                  </div>
                  <p className="mt-2">"The AI Office has revolutionized our call handling. It's like having a reliable assistant 24/7."</p>
                  <img src={require('../assets/images/quote.svg').default} alt="logo" className="quote-box" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>
      {/* testimonial section ends */}

      {/* faq section starts */}
      <section className="faq-section" data-aos="fade-up"
      >
        <Container>
          <h2 className="main-heading text-center mb-5">Frequently Asked Questions</h2>
          <Row>
            <Col lg={6}>
              <div className="faq-box">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="number-faq">01</span>
                      <span>How does AI Office work?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      AI Office uses advanced AI algorithms to answer calls, collect data, and enhance your business communication.

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="number-faq">02</span>
                      <span>Can I customize the AI responses?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely! AI Office offers easy customization, allowing you to tailor responses and caller journeys to suit your business needs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="last-child-case">
                      <span className="number-faq">03</span>
                      <span>Is it easy to update the system?</span>
                    </Accordion.Header>
                    <Accordion.Body className="last-child-bottom">
                      Yes, updating is a breeze. AI Office enables quick adjustments to keep your responses current and relevant.
                      Elevate your business communication with AI Office - your trusted virtual receptionist.
                      Ensure to incorporate relevant keywords related to your product and industry to enhance SEO. Adjust the content based on your specific features, benefits, and unique selling points.
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </Col>
            <Col lg={6}>
              <div className="ready-box">
                <h2 className="main-heading mb-4">Set up your AI Assistant or
                  teams in minutes</h2>
                <h5 className="mb-4">Contact our team
                </h5>
                <Button className="get-started-btn" onClick={() => navigate("/contact-us")} >
                  Get a demo <img src={require('../assets/images/arrow-icon-dark.svg').default} alt="logo" />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* faq section ends */}
    </>
  );
};
